.App {
  color: #3a3f3d;
}

/* inputのフォームの補完機能で色が変わらないようにしてる */
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s !important;
}

/* inputのボーダーを補完機能で色が変わらないようにしてる */
:focus {
  outline: none;
}
